import { Injectable } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { AngularFirestore } from '@angular/fire/firestore';

import { AngularFireStorage } from '@angular/fire/storage';
import { Observable, Timestamp } from 'rxjs';
import { Router } from "@angular/router";

import { Post } from '../interfaces/post.interface';
import { Baustellen } from '../interfaces/baustellen.interface';
import { Downloads } from '../interfaces/download.interface';
import { Startinfo } from '../interfaces/startinfo.interface';

//Zeitstempel mit Datum und Uhrzeit erstellen:
const date = firebase.firestore.FieldValue.serverTimestamp();
/* (firebase.firestore.Timestamp.now().seconds * 1000).toLocaleDateString(); */

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {
  private bstId: any;
  userData: any;
  email: string;

  private basePath = '/Test';


  constructor(
    public afStore: AngularFirestore,
    private storage: AngularFireStorage,
    public router: Router,
    private route: ActivatedRoute) {
  }

  createPost(
    absender: string,
    kBeschreib: string,
    lBeschreib: string,
    ueberschrift: string,
    bildUrl: Observable<string>,
  ): Promise<void> {
    const id = this.afStore.createId();

    //Rückgabewert AN Firestore nicht VON!!:
    return this.afStore.doc(`Postings/${id}`).set({
      id,
      absender,
      kBeschreib,
      lBeschreib,
      ueberschrift,
      date,
      bildUrl
    })

  }

  getPostings(): Observable<Post[]> {
    return this.afStore.collection<Post>(`Postings`, ref => ref.orderBy('date', 'desc').limit(8)).valueChanges();
  }

  getPostDetail(postId: string): Observable<Post> {
    return this.afStore.collection('Postings').doc<Post>(postId).valueChanges();
  }

  deletePost(postId: string): Promise<void> {
    return this.afStore.doc(`Postings/${postId}`).delete();
  }

  baustellenAbfrage(): Observable<Baustellen[]> {
    return this.afStore.collection<Baustellen>(`Baustellen`, ref => ref.orderBy('bstNummer', 'desc')).valueChanges({ idField: this.bstId });
  }

  async startseiteninfo(): Promise<Observable<Startinfo[]>> {
    return this.afStore.collection<Startinfo>('Startseiteninfo').valueChanges()
  }

  downloadNichtVorhanden(): Observable<Startinfo[]> {
    const fachrichtung = this.router.url.split('/').pop();
    return this.afStore.collection<Startinfo>('Downloads', id => id.where(firebase.firestore.FieldPath.documentId(), '==', fachrichtung)).valueChanges();
  }

  fileAbfrage(): Observable<Downloads[]> {
    const fachrichtung = this.router.url.split('/').pop();
    return this.afStore.collection<Downloads>('Downloads', data => data.where('fileRichtung', '==', fachrichtung)).valueChanges({ idField: 'dateiId' });
  }

  loescheDatei(fileUrl, dateiId) {
    this.afStore.collection('Downloads').doc(dateiId).delete().then(() => {
      this.storage.refFromURL(fileUrl).delete();
    }).catch((error) => {
      console.log(error)
    })
  }

}
