import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { forkJoin, Observable } from 'rxjs';
import { ProfilDaten } from '../../interfaces/profil.interface'
import { GruppenService } from '../gruppen.service';

@Component({
  selector: 'app-nutzermodal',
  templateUrl: './nutzermodal.page.html',
  styleUrls: ['./nutzermodal.page.scss'],
})
export class NutzermodalPage implements OnInit {
  public kontakte: Observable<ProfilDaten[]>

  email: string;
  id: string;
  nickname: string;

  person = [];


  constructor(
    private gruppenService: GruppenService,
    private modalCtrl: ModalController,
    private router: Router
  ) { }

  ngOnInit() {
    this.kontakte = this.gruppenService.erhalteNickname();
  }

  nutzerAbbruch() {
    this.modalCtrl.dismiss();
  }

  nutzerHinzufuegen() {
    const url = this.router.url.split('/').pop();
    let neueNutzer = this.person;

    return this.gruppenService.nutzerErgaenzen(url, neueNutzer)
    /*     .then(() => {
          this.router.navigateByUrl('/gruppenliste');
          this.person = null;
        }); */
  }

}
